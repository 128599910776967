import React from "react";
import AuthLayout from "./layouts/Auth";
import SettingsLayout from "./layouts/Settings";

const Index = React.lazy(() => import("./pages/index"));
const Workbench = React.lazy(() => import("./pages/workbench"));
const Resume = React.lazy(() => import("./pages/resume"));

const JobTableLayout = React.lazy(() => import("./pages/job-table"));

const JobAdd = React.lazy(() => import("./pages/job-add"));
const JobDemandAdd = React.lazy(() => import("./pages/job-demand-add"));
const Report = React.lazy(() => import("./pages/report"));
const ReportDetail = React.lazy(() => import("./pages/report-detail"));
const ReportOverview = React.lazy(() => import("./pages/report-overview"));
const SystemRecord = React.lazy(() => import("./pages/system-record"));
const MyTable = React.lazy(() => import("./pages/my-table"));
const ResumePreview = React.lazy(() => import("./pages/resume-preview"));
const UploadList = React.lazy(() => import("./pages/upload-list"));
const Login = React.lazy(() => import("./pages/login"));
const InterviewTable = React.lazy(() => import("./pages/interview-table"));
const Search = React.lazy(() => import("./pages/search"));
const OfferDetail = React.lazy(() => import("./pages/offer-detail"));
const Unassigned = React.lazy(() => import("./pages/unassigned"));

const Talent = React.lazy(() => import("./pages/talent"));
const TalentTable = React.lazy(() => import("./pages/talent/talent-table"));

// 设置中心
const SettingUser = React.lazy(() => import("./pages/settings/user"));
const SettingNotice = React.lazy(() => import("./pages/settings/notice"));
const TeamUsers = React.lazy(() => import("./pages/settings/team-users"));
const TeamAuth = React.lazy(() => import("./pages/settings/team-auth"));
const Address = React.lazy(() => import("./pages/settings/address"));
const Channel = React.lazy(() => import("./pages/settings/channel"));
const Source = React.lazy(() => import("./pages/settings/source"));
const Archive = React.lazy(() => import("./pages/settings/archive"));
const InterviewFeedback = React.lazy(() =>
  import("./pages/settings/interview-feedback")
);
const InterviewFeedbackList = React.lazy(() =>
  import("./pages/settings/interview-feedback/list-page")
);
const InterviewFeedbackAdd = React.lazy(() =>
  import("./pages/settings/interview-feedback/add-page")
);
const DemandFields = React.lazy(() => import("./pages/settings/demand-fields"));
const JobFields = React.lazy(() => import("./pages/settings/job-fields"));
const DuplicationCheck = React.lazy(() =>
  import("./pages/settings/duplication-check")
);
const InterviewSetting = React.lazy(() => import("./pages/settings/interview"));
const EmailsSetting = React.lazy(() => import("./pages/settings/emails"));
const TemplateSetting = React.lazy(() => import("./pages/settings/template"));
const ResumeFields = React.lazy(() => import("./pages/settings/resume-fields"));
const MemberFields = React.lazy(() => import("./pages/settings/member-fields"));
const ApprovalSetting = React.lazy(() => import("./pages/settings/approval"));
const DemandSetting = React.lazy(() =>
  import("./pages/settings/demand-setting")
);
const JobSetting = React.lazy(() => import("./pages/settings/job-setting"));
const ProtectionSetting = React.lazy(() =>
  import("./pages/settings/protection")
);
const StageCheckSetting = React.lazy(() =>
  import("./pages/settings/stage-check")
);
const StageActionSetting = React.lazy(() =>
  import("./pages/settings/stage-action")
);
const ResumeAutoSetting = React.lazy(() =>
  import("./pages/settings/resume-auto")
);

const Portal = React.lazy(() => import("./pages/portal"));

const Extensions = React.lazy(() => import("./pages/web/extensions"));
const DingTalkCallBack = React.lazy(() =>
  import("./pages/web/dingtalk-login-callback")
);
const OfferFeedback = React.lazy(() => import("./pages/web/offer-feedback"));

const TeamsSelect = React.lazy(() => import("./pages/teams/select"));

const Test = React.lazy(() => import("./pages/test"));

const NotFound = React.lazy(() => import("./pages/error/404"));
const NoAuth = React.lazy(() => import("./pages/error/403"));
const NoLogin = React.lazy(() => import("./pages/error/401"));
const UpgradePage = React.lazy(() => import("./pages/error/502"));

// https://reactrouter.com/docs/en/v6/examples/route-objects
const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        name: "首页",
        element: <Index />,
        children: [],
      },
      {
        path: "/workbench",
        name: "工作台",
        element: <Workbench />,
      },
      {
        path: "/resume",
        name: "简历",
        element: <Resume />,
      },
      {
        path: "/job-table",
        name: "简历和需求列表",
        element: <JobTableLayout />,
      },
      {
        path: "/my-table",
        name: "我的评估和面试",
        element: <MyTable />,
      },
      {
        path: "/job-add",
        name: "新增职位",
        element: <JobAdd />,
      },
      {
        path: "/job-demand-add",
        name: "新增招聘需求",
        element: <JobDemandAdd />,
      },
      {
        path: "/report",
        name: "报表总览",
        element: <Report />,
        children: [],
      },
      {
        path: "/report-overview",
        name: "数据报表列表",
        element: <ReportOverview />,
        children: [],
      },
      {
        path: "/report-detail",
        name: "报表详情",
        element: <ReportDetail />,
        children: [],
      },
      {
        path: "/system-record",
        name: "系统操作记录",
        element: <SystemRecord />,
        children: [],
      },
      {
        path: "/resume-preview",
        name: "简历预览",
        element: <ResumePreview />,
      },
      {
        path: "/upload-list",
        name: "上传简历",
        element: <UploadList />,
      },
      {
        path: "/interview-table",
        name: "面试表格",
        element: <InterviewTable />,
      },
      {
        path: "/talent",
        name: "人才库",
        element: <Talent />,
      },
      {
        path: "/talent-table",
        name: "人才库表格",
        element: <TalentTable />,
      },
      {
        path: "/search",
        name: "搜索页面",
        element: <Search />,
      },
      {
        path: "/offer-detail",
        name: "录用信息页面",
        element: <OfferDetail />,
      },
      {
        path: "/Unassigned",
        name: "未分配简历",
        element: <Unassigned />,
      },
      {
        path: "/settings",
        name: "设置中心",
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            path: "user",
            name: "个人中心",
            element: <SettingUser />,
          },
          {
            path: "team-users",
            name: "团队成员设置",
            element: <TeamUsers />,
          },
          {
            path: "team-auth",
            name: "角色权限设置",
            element: <TeamAuth />,
          },
          {
            path: "address",
            name: "地址管理",
            element: <Address />,
          },
          {
            path: "notice",
            name: "通知消息设置",
            element: <SettingNotice />,
          },
          {
            path: "channel",
            name: "渠道管理",
            element: <Channel />,
          },
          {
            path: "source",
            name: "来源管理",
            element: <Source />,
          },
          {
            path: "archive",
            name: "简历终止招聘原因",
            element: <Archive />,
          },
          {
            path: "interview-feedback",
            name: "面试评价表",
            element: <InterviewFeedback />,
            children: [
              {
                index: true,
                name: "面试评价表",
                element: <InterviewFeedbackList />,
              },
              {
                path: "add",
                name: "添加面试评价表",
                element: <InterviewFeedbackAdd />,
              },
            ],
          },
          {
            path: "demand-fields",
            name: "招聘需求字段管理",
            element: <DemandFields />,
          },
          {
            path: "job-fields",
            name: "职位字段管理",
            element: <JobFields />,
          },
          {
            path: "duplication-check",
            name: "简历查重设置",
            element: <DuplicationCheck />,
          },
          {
            path: "protection",
            name: "保护期设置",
            element: <ProtectionSetting />,
          },
          {
            path: "interview",
            name: "筛选面试反馈设置",
            element: <InterviewSetting />,
          },
          {
            path: "emails",
            name: "邮箱收取简历",
            element: <EmailsSetting />,
          },
          {
            path: "template",
            name: "候选人通知模板设置",
            element: <TemplateSetting />,
          },
          {
            path: "resume-fields",
            name: "候选人字段管理",
            element: <ResumeFields />,
          },
          {
            path: "member-fields",
            name: "成员字段管理",
            element: <MemberFields />,
          },
          {
            path: "approval",
            name: "审批管理",
            element: <ApprovalSetting />,
          },
          {
            path: "demand-setting",
            name: "招聘需求管理",
            element: <DemandSetting />,
          },
          {
            path: "job-setting",
            name: "职位管理",
            element: <JobSetting />,
          },
          {
            path: "stage-check",
            name: "简历各阶段校验规则",
            element: <StageCheckSetting />,
          },
          {
            path: "stage-action",
            name: "招聘阶段可操作项",
            element: <StageActionSetting />,
          },
          {
            path: "resume-auto",
            name: "简历自动化流转",
            element: <ResumeAutoSetting />,
          },
        ],
      },

      {
        path: "/test",
        name: "test",
        element: <Test />,
      },
    ],
  },
  {
    path: "/web",
    name: "web相关页面",
    children: [
      {
        path: "extensions",
        name: "插件下载页面",
        element: <Extensions />,
      },
      {
        path: "dingtalk-login-callback",
        name: "登录回调",
        element: <DingTalkCallBack />,
      },
      {
        path: "offer-feedback/:teamKey/:candidateId/:token",
        name: "是否接受职位",
        element: <OfferFeedback />,
      },
    ],
  },
  {
    path: "/portal",
    name: "应用内授权",
    element: <Portal />,
  },
  {
    path: "/teams",
    name: "团队",
    children: [
      {
        path: "select",
        name: "选择团队",
        element: <TeamsSelect />,
      },
    ],
  },
  {
    path: "/login",
    name: "登录",
    element: <Login />,
  },
  {
    path: "/403",
    name: "403",
    element: <NoAuth />,
    children: [],
  },
  {
    path: "/401",
    name: "401",
    element: <NoLogin />,
    children: [],
  },
  {
    path: "/502",
    name: "502",
    element: <UpgradePage />,
    children: [],
  },
  {
    path: "*",
    name: "404",
    element: <NotFound />,
  },
];

export default routes;
